<template>
  <v-card class="pa-2" style="min-height: 80vh; max-height: 80vh; overflow-y: hidden">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card-title class="pb-2 pl-2">
          <span>{{ $lang.labels.comment }}</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="primary"
            text
            large
            @click="$emit('closeDialog', true)"
          >
            X
          </v-btn>
        </v-card-title>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="6" class="px-2">
            <v-col cols="12" class="pa-2 calculated-height" style="border: #9e9e9e 1px solid; border-radius: 4px">
              <textarea
                ref="textareaRef"
                v-debounce:500="handleDebounce"
                style="height: 100%"
                :value="localMarkdownData"
              ></textarea>
            </v-col>
          </v-col>
          <v-col cols="6" class="px-2">
            <v-col cols="12" class="pa-2 calculated-height" style="border: #9e9e9e 1px solid; border-radius: 4px">
              <div v-html="compiledMarkdown"></div>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters justify="space-between" class="pt-2 px-2">
      <v-btn
        color="secondary"
        @click="$emit('closeDialog', true)"
      >
        <v-icon
          left
          dark
        >
          mdi-arrow-left
        </v-icon>
        <span class="ml-1">{{ $lang.actions.back }}</span>
      </v-btn>
      <v-btn
        color="success"

        @click="$emit('save', localMarkdownData); $emit('closeDialog', true)"
      >
        {{ $lang.actions.save }}
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
import { marked } from 'marked'

export default {
  props: {
    markdownData: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      localMarkdownData: ''
    }
  },
  computed: {
    compiledMarkdown: function() {
      return marked.parse(this.localMarkdownData)
    }
  },
  created() {
    this.localMarkdownData = this.markdownData
  },
  mounted() {
    setTimeout(() => {
      this.$refs.textareaRef.focus()
    }, 100)
  },
  methods: {
    handleDebounce(val, event) {
      this.localMarkdownData = val
    }
  }
}
</script>
<style lang="scss" scoped>
.calculated-height {
  height: calc(80vh - (60px + 32px + 52px));
}

.font-change {
  font-family: "Courier New", Courier, monospace !important;
}

.v-input__control, .v-input__slot, .v-text-field__slot, textarea {
  height: 100%;
  width: 100%;
  color: #9e9e9e;
  border: 1px #2d2d2d solid;
  border-radius: 4px;
  padding: 8px;
}

.v-text-field fieldset, .v-text-field .v-input__control {
  height: 100%;
}

.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
  height: 100%;
}
</style>
