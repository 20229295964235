<template>
  <div class="d-flex" style="width: 100%">
    <v-autocomplete
      v-model="validationRuleId"
      outlined
      dense
      clearable
      hide-no-data
      :items="filteredValidations"
      item-text="name"
      item-value="id"
      :label="label || $lang.labels.validation"
      :placeholder="$lang.actions.startTyping"
      prepend-inner-icon="mdi-cloud-search-outline"
      :readonly="!userCanEdit"
      :data-cy="`field-ProcessValidation-${type}`"
      :rules="required ? [v => !!v || $lang.labels.required] : []"
      :class="[required && 'required-asterisk', 'clickable-simple']"
      :search-input.sync="search"
    >
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title :data-cy="item.id">{{ item.name }}</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-btn
      text
      class="ml-1"
      color="primary"
      :disabled="!validationRuleId"
      :data-cy="`open-validation-${type}`"
      @click="openValidation(validationRuleId)"
    >
      {{ $lang.actions.openTemplate }}
    </v-btn>
  </div>
</template>

<script>
import {
  getValidationRuleByIdUsingGET as getValidation,
  getValidationRulesUsingGET as getValidations
} from '@/utils/api'

export default {
  props: {
    itemValidationId: {
      type: Number,
      default: () => 0
    },
    type: {
      type: String,
      default: 'input'
    },
    label: {
      type: String,
      default: null
    },
    userCanEdit: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    resourceIsSystem: {
      type: Boolean,
      default: false
    }
  },
  inject: ['openValidation'],
  data() {
    return {
      filteredValidations: [],
      search: '',
      validationRuleId: '',
      isLoadingValidations: false,
      initDone: false
    }
  },
  watch: {
    search(val, oldVal) {
      if (!val) {
        this.filteredValidations = []

        return
      }

      if (val === oldVal) return

      this.searchValidations()
    },
    'validationRuleId': {
      handler(val) {
        if (this.initDone) this.$emit('validationRuleIdChanged', val)
      },
      immediate: true
    }
  },
  mounted() {
    if (this.itemValidationId) {
      getValidation({ id: this.itemValidationId })
        .then((res) => {
          this.validationRuleId = res.data.data.id
          this.search = res.data.data.name
          this.filteredValidations = [res.data.data]
          setTimeout(() => {
            this.initDone = true
          }, 5)
        })
    } else {
      setTimeout(() => {
        this.initDone = true
      }, 5)
    }
  },
  methods: {
    searchValidations() {
      if (!this.initDone) return

      this.filteredValidations = []

      if (!this.search || !this.search.length || this.search.length <= 1) return
      this.isLoadingValidations = true

      getValidations({ name: this.search, isSystem: this.resourceIsSystem })
        .then((res) => {
          this.filteredValidations = res.data.data.items
          this.isLoadingValidations = false
        })
        .catch((err) => {
          this.isLoadingValidations = false

          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    }
  }
}
</script>
