<template>
  <v-card class="pa-2" style="height: 80vh; overflow-y: hidden">
    <v-card-title class="pb-2">
      <span>{{ isStep ? $lang.header.addStep : $lang.header.types }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="primary"
        text
        large
        @click="$emit('closeDialog', true)"
      >
        X
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-text-field
      v-model="search"
      dense
      color="primary"
      :label="isStep ? $lang.actions.searchStep : $lang.actions.searchCredentialType"
      data-cy="add-step-search"
      class="pt-3"
      autofocus
      @keydown.down.prevent="onArrowDown"
      @keydown.up.prevent="onArrowUp"
      @keydown.enter.prevent="onEnter"
    ></v-text-field>
    <v-list class="calculated-height-modal" style="overflow-y: auto">
      <v-list-item-group
        color="primary"
      >
        <template v-for="(item, i) in typesFormatted" style="overflow-y: auto">
          <v-list-item
            :key="i"
            :class="['to-select', focusedIndex === i ? 'selected' : '']"
            :data-cy="`credential-${item.toLowerCase()}-create`"
            @click="selectItem(item)"
          >
            <v-list-item-content class="to-select">
              <v-list-item-title v-if="item === 'PASTE'" class="to-paste">
                {{ $lang.status[item] }}
              </v-list-item-title>
              <v-list-item-title v-else class="to-select">
                {{ $lang.status[item] }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: {
    types: {
      type: Array,
      default: () => []
    },
    isStep: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      search: '',
      focusedIndex: -1
    }
  },
  computed: {
    typesFormatted() {
      return this.search.length === 0 ? this.types : this.types.filter((item) =>
        item.toLowerCase().includes(this.search.toLowerCase()) || this.$lang.status[item].toLowerCase().includes(this.search.toLowerCase()))
    }
  },
  methods: {
    onArrowDown() {
      if (this.focusedIndex < this.typesFormatted.length - 1) {
        this.focusedIndex++
      }
    },
    onArrowUp() {
      if (this.focusedIndex > 0) {
        this.focusedIndex--
      }
    },
    onEnter() {
      if (this.focusedIndex >= 0 && this.focusedIndex < this.typesFormatted.length) {
        this.selectItem(this.typesFormatted[this.focusedIndex])
      }
    },
    selectItem(item) {
      this.$emit('create', item)
    }
  }
}
</script>
<style lang="scss" scoped>
.to-select:hover, .selected {
  color: var(--v-primary-base) !important;
  background-color: var(--v-surface-base) !important;
}

.to-paste:hover {
  color: var(--v-success-base) !important;
}

.calculated-height-modal {
  max-height: calc(80vh - 155px);
}
</style>
