<template>
  <div>
    <v-datetime-picker
      ref="dateTimePickerStepProperties"
      v-model="dateTimeString"
      :label="$lang.labels.date"
      no-title
      :time-picker-props="{ format: '24hr' }"
      :text-field-props="{ outlined: true, dense: true }"
      @input="onDateTimeSelected"
      @click:outside="onDialogClosed"
    >
      <template v-slot:dateIcon="{}">
        <v-icon>mdi-calendar</v-icon>
      </template>
      <template v-slot:timeIcon="{}">
        <v-icon>mdi-clock-outline</v-icon>
      </template>
    </v-datetime-picker>
  </div>
</template>

<script>

import { convertISOToDate } from '@/utils/helpers'

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data: () => ({
    dateTimeString: '',
    dataPositionString: '',
    isDialogOpen: false
  }),
  watch: {
    dateTimeString: {
      handler(val) {
        this.$emit('dataChanged', { value: val, target: this.dataPositionString })
      }
    },
    isDialogOpen(newVal, oldVal) {
      if (!newVal && oldVal) {
        this.onDialogClosed()
      }
    }
  },
  mounted() {
    // Watch the dialog's display property directly
    this.$watch(
      () => this.$refs.dateTimePickerStepProperties.display,
      (newVal) => {
        this.isDialogOpen = newVal
      }
    )
  },
  created() {
    if (this.data && this.data.target) {
      this.dataPositionString = this.data.target
    }
    if (this.data && this.data.value) {
      if (this.$options.filters.javaVariableConventionRules(this.data.value, true)) {
        this.dateTimeString = ''
        this.$emit('dataChanged', { value: '', target: this.data.target })
      } else {
        this.dateTimeString = convertISOToDate(this.data.value)
      }
    }
    setTimeout(() => {
      this.$refs.dateTimePickerStepProperties.display = true
    }, 40)
  },
  methods: {
    onDateTimeSelected(val) {
      this.$emit('dataChanged', { value: val, target: this.dataPositionString })
      this.$emit('closeDialog')
    },
    onDialogClosed() {
      this.$emit('closeDialog')
    }
  }
}
</script>
