<template>
  <v-card class="pa-2" style="min-height: 80vh; max-height: 80vh; overflow-y: hidden">
    <v-row v-if="success" no-gutters>
      <v-col cols="12">
        <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
      </v-col>
    </v-row>
    <v-card-title class="pb-2">
      <span>{{ $lang.labels.debugLogData }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="primary"
        text
        large
        @click="$emit('closeDialog', true)"
      >
        X
      </v-btn>
    </v-card-title>
    <v-row no-gutters>
      <v-col cols="12" class="pt-2">
        <div class="calculated-height" style="overflow-y: auto;">
          <v-tooltip v-if="debugLogData" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                text
                large
                v-bind="attrs"
                v-on="on"
                @click="clipboardCopy(debugLogData)"
              >
                <v-icon>mdi-vector-combine</v-icon>
              </v-btn>
            </template>
            <span>{{ $lang.labels.copy }}</span>
          </v-tooltip>
          <p class="color-primary font-change pt-2">{{ localDebugLogData }}</p>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'LogDebugModal',
  props: {
    debugLogData: {
      type: [Object, String],
      default: () => {}
    }
  },
  data () {
    return {
      tab: 0,
      tempArr: [],
      success: '',
      localDebugLogData: ''
    }
  },
  created() {
    if (this.debugLogData) {
      this.localDebugLogData = this.debugLogData
    }
  },
  methods: {
    clipboardCopy(type) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard

      clipboardData.writeText(type)

      this.success = this.$lang.success.copiedClipboard

      setTimeout(() => this.success = '', 5000)
    }
  }
}
</script>
<style lang="scss" scoped>
.calculated-height {
  max-height: calc(80vh - 230px);
}

.font-change {
  font-family: "Courier New", Courier, monospace !important;
}
</style>
