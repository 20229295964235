<template>
  <v-card class="pa-2" style="min-height: 80vh; max-height: 80vh; overflow-y: hidden">
    <v-card-title class="pb-2">
      <span>{{ $lang.labels.exception }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="primary"
        text
        large
        @click="$emit('closeDialog', true)"
      >
        X
      </v-btn>
    </v-card-title>
    <v-row no-gutters>
      <v-col cols="12">
        <v-tabs
          v-model="tab"
          class="pb-1"
        >
          <v-tabs-slider color="accent"></v-tabs-slider>

          <v-tab
            :key="0"
            :disabled="!exception.exceptionMessage"
          >
            {{ $lang.header.exceptionMessage }}
          </v-tab>
          <v-tab
            :key="1"
            :disabled="!exception.exceptionStackTrace"
          >
            {{ $lang.header.exceptionStackTrace }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            :key="0"
          >
            <v-row no-gutters>
              <v-col cols="12" class="pt-2">
                <div class="calculated-height" style="overflow-y: auto;">
                  <v-tooltip v-if="exception && exception.exceptionMessage" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        text
                        large
                        v-bind="attrs"
                        v-on="on"
                        @click="clipboardCopy(exception.exceptionMessage)"
                      >
                        <v-icon>mdi-vector-combine</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $lang.labels.copy }}</span>
                  </v-tooltip>
                  <p class="color-primary font-change" v-html="localException && localException.exceptionMessage ? localException.exceptionMessage : $lang.hints.noExceptionMessage"></p>
                </div>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item
            :key="1"
          >
            <v-row no-gutters>
              <v-col cols="12" class="pt-2">
                <div class="calculated-height" style="overflow-y: auto;">
                  <v-tooltip v-if="exception && exception.exceptionStackTrace" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        text
                        large
                        v-bind="attrs"
                        v-on="on"
                        @click="clipboardCopy(exception.exceptionStackTrace)"
                      >
                        <v-icon>mdi-vector-combine</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $lang.labels.copy }}</span>
                  </v-tooltip>
                  <p class="color-primary font-change" v-html="localException && localException.exceptionStackTrace ? localException.exceptionStackTrace : $lang.hints.noExceptionStackTrace"></p>
                </div>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {

  props: {
    exception: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      tab: 0,
      tempArr: [],
      localException: {}
    }
  },
  created() {
    if (this.exception && this.exception.exceptionMessage) {
      this.localException.exceptionMessage = this.exception.exceptionMessage
    }
    if (this.exception && this.exception.exceptionStackTrace) {
      this.localException.exceptionStackTrace = this.exception.exceptionStackTrace
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    clipboardCopy(type) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard

      clipboardData.writeText(type)

      this.addSnackbar({
        message: this.$lang.success.copiedClipboard,
        timeout: 5000,
        color: 'success'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.calculated-height {
  max-height: calc(80vh - 230px);
}

.font-change {
  font-family: "Courier New", Courier, monospace !important;
}
</style>
